<template>
  <div class="orderDetail-container">
      <div class="orderDetail-box">
          <div style="width:780px; margin-left:50px;">
          <!-- <div class="orderDetail-box-top">
              <div>订单详情</div>
          </div> -->
          <!-- 订单信息 -->
          <div class="orderDetail-box-orderInfo">订单信息</div>
          <div class="orderDetail-box-infor">
             <div>
                 <div>
                     <span>订单号:</span>
                     <span>{{order_id}}</span>
                 </div>
                 <div>
                     <span>商品名称:</span>
                     <span>{{click_type}}</span>
                 </div>
                 <div>
                     <span>下单时间:</span>
                     <span>{{created_at}}</span>
                 </div>
                 <div v-if="ident == '2'&&angentType!='3'">
                     <span>协议价格:</span>
                     <span>￥ {{angencyprice}}</span>
                 </div>
             </div>
             <div>
                 <div>
                     <span>订单状态:</span>
                     <span>{{orderStatus}}</span>
                 </div>
                 <div>
                     <span>服务类型:</span>
                     <span>{{regist_num}}</span>
                 </div>
                 <div>
                     <span>支付方式:</span>
                     <span>{{pay_type}}</span>
                 </div>
                 <div v-if="ident == '2'&&angentType!='3'">
                     <span>预估收益:</span>
                     <span>￥ {{hopePrice}}</span>
                 </div>
             </div>
             <div>
                 <div>
                     <span>优惠金额:</span>
                     <span>￥ {{coupon}}</span>
                 </div>
                 <div>
                     <span>服务价格:</span>
                     <span>￥ {{price}}</span>
                 </div>
                 <div v-if="platformType == 'HT'">
                     <span>顾问服务费:</span>
                     <span>￥ 100</span>
                 </div>
                 <div>
                     <span>支付金额:</span>
                     <span>￥ {{total_price}}</span>
                 </div>
                 
             </div>
          </div>
          <div class="orderDetail-address">
              <div>邮寄地址:</div>
              <div>{{address}}</div>
              <!-- <div @click="addresstan">修改</div> -->
          </div>
          <div class="orderDetail-no">
              <div v-if="bankReason != ''&&bankReason != null">
                  <span>审核失败原因:</span>
                  <span>{{bankReason}}</span>
              </div>
              <div v-if="refunButtomStuts == '16'">
                  <span>退款失败原因:</span>
                  <span>{{apprReason}}</span>
              </div>
              <div v-if="refunButtomStuts == '4'">
                <span>资料审核失败原因:</span>
                <span>{{apprReason}}</span>
            </div>
          </div>
          <div class="orderDetail-box-changeTitle">
               <div :class="change">
                   <div class="div1" @click="tab('infor')">商标信息</div>
                   <div class="div2" @click="tab('about')">关联订单</div>
               </div>
          </div>
          <div class="orderDetail-box-tradeMark" v-show="show1">
              <div class="orderDetail-box-image">
                  <div>商标图形:</div>
                  <div>
                      <img :src="brand_img" alt="">
                  </div>
                  <div>
                      <span>商标状态:</span>
                      <span>{{status}}</span>
                  </div>
                  <div>
                      <span>有效日期:</span>
                      <span v-if="time1">{{time1}}</span>
                  </div>
              </div>
              <div class="orderDetail-box-information">
                  <span class="span1">申请人名称：</span>
                  <span class="span2">{{name}}</span>
                  <span>申请/注册号:</span>
                  <span class="span3">{{regid}}</span>
                  <span>申请日期:</span>
                  <span>{{applydate}}</span>
              </div>
              <div class="orderDeatil-box-choseTy">
                  <div class="orderDetail-box-good-type">商品类别：</div>
                  <div class="orderDetail-box-good-detail">
                        <div class="orderDetail-box-good-detail-div1" v-if="intcls"><span>{{intcls}}</span>类
                        <span style="margin-left:40px;" v-if="regist_num == '商标注册'&&rate!=='0'">下证率 {{rate}}%</span>
                        <div class="star-div" v-if="regist_num=='商标注册'">
                            <span>成功指数</span>
                            <el-rate
                                v-model="star"
                                disabled
                                score-template="{value}">
                            </el-rate>
                        </div>
                        </div>
                        <div class="orderDetail-box-good-detail-div2" >
                            <div v-for="(v,k) in three_cate" :key="k">
                                <!-- <span>{{v.num}}</span> -->
                                <span>{{v.groupName}}</span>
                            </div>
        
                        </div>
                    </div>
              </div>
          </div>
          <div class="orderDetail-box-risk" v-show="show2">
              <div class="orderDetail-box-risk-title">
                  <span>商品名称</span>
                  <span>办理时间</span>
                  <span>订单编号</span>
              </div>
              <div class="orderDetail-box-planDetail">
                    <el-timeline>
                        <el-timeline-item
                        v-for="(activity, index) in activities"
                        :key="index"
                        :timestamp="activity.createdTime">
                        {{activity.serviceTypeName}}
                        <span>{{activity.orderCode}}</span>
                        </el-timeline-item>
                    </el-timeline>
                </div>
          </div>
          <div class="orderDetail-box-rate">订单进度</div>
          <div class="orderDetail-box-pragrom">
                <div class="orderDetail-box-pragrom1"><span>当前进度：</span><span>{{orderStatus}}</span><span @click="watchPrograms">查看全部进度</span></div>
                <div class="orderDetail-box-pragrom2" v-show="progeramShow">
                    <el-timeline>
                        <el-timeline-item
                        v-for="(activity, index) in orderDetailp"
                        :key="index"
                        :timestamp="activity.createdTime">
                        {{activity.orderScheduleInfo}}
                        <span></span>
                        </el-timeline-item>
                    </el-timeline>
                </div>
          </div>
          <div class="orderDetail-box-file" v-show="fileshow">
              <div class="orderDetail-box-file-infor">相关文件信息</div>
              <div class="orderDetail-box-file-wrap">
                  <div class="orderDetail-box-file-top" v-for="(v,k) in fileArr" :key="k">
                    <div class="orderDetail-box-file-top-img"></div>
                    <div class="orderDetail-box-file-top-div">
                        <div>{{v.protocolName}}</div>
                        <div>
                            <a :href="v.protocolUrl" target="_blank">查看</a>
                        </div>
                    </div>
                 </div>
                  
              </div>
              <div style="clear:both;"></div>
          </div>
          <!-- <div class="orderDetail-box-suggest">
              <div class="orderDetail-box-suggest-top">关联业务推荐</div>
              <div class="orderDetail-box-suggest-detail">
                  <div v-for="(v,k) in recommend" @click="totrade(v.cate_id,v.pid,v.id)">
                      <img :src="GLOBAL.base_url+v.pic" alt="">
                      <p>{{v.title}}</p>
                      <p>{{Number(v.price)+Number(v.service_price)}}</p>
                  </div>
              </div>
          </div> -->
          <div class="orderDetail-box-refund" v-show="refundshow">
              <div>退款信息</div>
              <div @click="backdraw">申请退款 ></div>
          </div>
          <div class="drawback" v-show="backshow">
              <div class="drawback-div">
                  <img :src="require('/static/images/orderdetailcha.png')" alt="" @click="ordercha">
                  <div>
                    <div class="back-div1">申请退款</div>
                    <div class="back-div2">
                        <div>退款原因</div>
                        <div class="back-div-chose" v-for="(v,k) in refundChose" :key="k" @click="choseX(v.id)" :class="{refundStyle:v.id == reson}">{{v.title}}</div>
                    </div>
                    <div class="back-div3">
                        <span>退款账号</span>
                        <input type="text" v-model="refundcount" placeholder="选填，仅退款至银行卡需要">
                    </div>
                    <div class="back-div4">
                        <div>详细说明</div>
                        <div>
                            <textarea name="" id="" class="write" v-model="refundreason"></textarea>
                        </div>
                    </div>
                    <div class="back-div5" @click="refundsubmit">提交</div>
                  </div>
              </div>
          </div>
          
        </div>
      </div>
    <div style="clear:both;"></div>
     <!-- 选择地址弹框 -->
      <div class="address-pick" v-show="addressshow">
          <div class="pick-form">
              <img :src="require('/static/images/cha.png')" alt="" @click="cha">
              <div class="form-pick">
                  <div v-for="(item , index) in dataArr" :key="index" :class="{colorChange:item.id==chosedynamic}" @click="addressone(item.id)">
                      <span>{{item.consignee}}</span><span>{{item.mobile}}</span>{{item.province}}{{item.city}}{{item.area}}{{item.address}}
                  </div>
              </div>
              <div class="address-edit" @click="editaddress">修改</div>
          </div>
      </div>
  </div>

</template>

<script>
export default {
  name: 'orderDetail',
  data(){
      return{
        good:'',
        type:'',
        fileshow:Boolean,
        //  判断鼠标移入消失隐藏
        
        activities: [],
        change:'infor',
        show1:true,
        show2:false,
        
        // 订单信息字段
         order_id:'',
        click_type:'',
        coupon:'',
        orderStatus:'',
        price:'',
        created_at:'',
        pay_type:'',
        total_price:'',
        regist_num:'',
        order_schedule:'',
        orderrefundstatus:'',
        address:'',
        addressshow:false,
        dataArr:[],
        chosedynamic:'',
        // 订单进度框里面的内容
        orderDetail:[],
        orderDetailp:[],
        
        // 商标信息里的字段
        brand_img:'',
        status:'',
        time1:'',
        time2:'',
        name:'',
        regid:'',
        intcls:'',
        intcls_name:'',
        applydate:'',
        Ggdate:'',
        brand_upload:{},
        three_cate:[],
        // 退款弹窗
        backshow:false,
        // 判断退款和退款状态显示隐藏
        refundshow:false,
        
        // 退款账号
        refundcount:'',
        // 退款原因
        refundreason:'',
        reson:'',
        // 退款审核失败原因
        apprReason:'',
        refundChose:[{
            id:'1',
            title:'服务选择错误'
        },{
            id:'2',
            title:'不需要了'
        },{
            id:'3',
            title:'其他原因'
        }],
        
        // 合同
        contract:'',
        // 推荐业务
        recommend:[],
        // 星星
        star:'',
        person_company:'',
        // 协议价格
        angencyprice:'',
        // 预估收益
        hopePrice:'',
        // 订单进度详情
        progeramShow:false,
        fileArr:[],
        // 判断退款按钮是否存在
        refunButtomStuts:'',
        rate:'',
        // 获取身份
        ident:this.$cookie.getCookie('user_type'),
        // 银行转账失败原因
        bankReason:'',
        // 是否是城市代理人
        angentType:this.$cookie.getCookie('angentType'),
        // 确认是否是顾问下的单
        platformType:''
      }
  },
  components: {
  },
  methods:{
      tip(type,msg){
          return this.$message({
              type:type,
              message:msg,
              showClose:true,
              duration:1500
          })
      },
      cha(){
          this.addressshow = false;
      },
      addresstan(){
          this.addressshow = true;
      },
      addressone(val){
          this.chosedynamic = val;
      },
      editaddress(){
          this.$http.post(this.GLOBAL.base_url+'/api/order/orderEditAddress',{
                 order_id:this.order_id,
                 address_id:this.chosedynamic
            }).then(res=>{
                // console.log(res)
                if(res.data.code == "1"){
                    this.tip('success','修改成功')
                    this.$router.go(0);
                }else{
                    this.addressshow = false;
                    this.tip('success',res.data.msg)
                }
            })
      },
      tab(item){
          this.change = item;
          if(this.change == "infor"){
              this.show1 = true;
              this.show2 = false;
          }else if(this.change == "about"){
              this.show1 = false;
              this.show2 = true;
          }
      },
      backdraw(){
          this.backshow = true;
      },
      ordercha(){
          this.backshow = false;
      },
      totrade(val1,val2,val3){
          this.$router.push({
              path:'/tradeDetail',
              query:{
                  tradeid:val1,
                  secondid:val3,
                  id:val2
              }
          })
      },
      choseX(val){
          this.reson = val
      },
    //   退款
    refundsubmit(){
        if(this.reson == ''){
             this.tip('error','请选择退款原因')
            return false;
        }if(this.refundreason == ''){
             this.tip('error','请填写退款详细')
            return false;
        }else{
            this.$http.post(this.GLOBAL.new_url+'/orderv2/webapi/refundOrder',{
                 orderCode:this.$route.query.id,
                 refundReason:this.reson,
                  refundAccount:this.refundcount,
                 refundDescription:this.refundreason
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                // console.log(res)
                if(res.data.code == "200"){
                    this.getdata();
                    this.getProgram()
                    this.backshow = false;
                    this.tip('success','您的退款已提交')
                }
            })
        }
    },
      getdata(){
          this.$http.post(this.GLOBAL.new_url+'/orderv2/webapi/getOrderInfo',{
                 orderCode:this.$route.query.id
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                // console.log(res)
               if(res.data.code == "200"){
                var backdata = res.data.data;
                this.order_id = backdata.orderCode;
                this.regist_num = backdata.goodsName;
                this.created_at = backdata.createdTime;
                this.orderStatus = backdata.orderStatusMsg;
                this.refunButtomStuts = backdata.orderStatus;
                this.platformType = backdata.platformType;
                if(this.refunButtomStuts == "2"||this.refunButtomStuts == "4"){
                    this.refundshow = true
                }else{
                    this.refundshow = false
                }  
                this.rate = backdata.randomNum;
                this.bankReason =  backdata.note;
                this.click_type = backdata.serviceTypeName;
                if(backdata.payType == "1"){
                    this.pay_type = '微信支付'
                }else if(backdata.payType == "2"){
                    this.pay_type = '支付宝支付'
                }else{
                    this.pay_type = '银行转账'
                }
                this.apprReason = backdata.apprReason;
                this.coupon = backdata.couponMoney;
                this.price = backdata.servicePrice;
                this.total_price = backdata.totalFee;
                this.angencyprice = backdata.agreementPrice+backdata.smallwareAgreementPrice;
                this.hopePrice = backdata.earningsPrice;
                this.address = backdata.addressSite;
                this.brand_img = backdata.trademarkImgUrl;
                this.status= backdata.trademarkStatus;
                this.time1 = backdata.privateTime;
                this.name = backdata.authName;
                this.regid = backdata.registerNumber;
                this.applydate = backdata.requestDate;                                                                                                                                                                                                  
                this.intcls = backdata.oneCate;
                this.star = Number(backdata.successRate);
                this.three_cate = backdata.groupNameAos;
                //  获取关联订单信息
                if(this.regist_num == "商标注册"){
                    this.$http.post(this.GLOBAL.new_url+'/orderv2/webapi/queryCorrelationOrderInfo',{
                        orderCode:this.$route.query.id,
                        tradeCode:backdata.tradeCode,
                        userCode:this.$cookie.getCookie('u_id'),
                        trademarkName:backdata.trademarkName,
                        serviceTypeName:this.click_type,
                    },{
                            headers:{
                                AuthToken:this.$cookie.getCookie('token'),
                                'X-Request-Origin':'xcx'
                            }
                                        }).then(res=>{
                                            // console.log(res)
                                            if(res.data.code == '200'){
                                                this.activities = res.data.data.list;
                                            }
                                        })
                        }
                
               }
            })
      },
    
    // 获取订单进度
    getProgram(){
        this.$http.post(this.GLOBAL.new_url+'/orderv2/webapi/orderSchedule',{
                 orderCode:this.$route.query.id,
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                    // console.log(res)
                                    if(res.data.code == '200'){
                                        this.orderDetailp = res.data.data.list;
                                    }
                                })
    },
    watchPrograms(){
        this.progeramShow = !this.progeramShow
    },
    getFile(){
        this.$http.post(this.GLOBAL.new_url+'/orderv2/webapi/getOrderProtocolInfo',{
                 orderCode:this.$route.query.id,
                 userCode:this.$cookie.getCookie('u_id')
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                    // console.log(res)
                                    if(res.data.code == '200'){
                                        this.fileArr = res.data.data;
                                    }
                                })
    }
    
  },
  created(){
      this.getdata();
  },
  mounted(){
      this.getProgram();
      this.getFile()
  }
}

</script>

<style>
    .orderDetail-box{
        width:1015px;
        margin-left: 16px;
        margin-top: 12px;
        float: left;
        background-color: #fff;
    }
    .orderDetail-box-top{
        height: 43px;
        padding-top: 64px;
        border-bottom: 1px solid #d8d8d8;
    }
    .orderDetail-box-top>div{
        width: 96px;
        height: 33px;
        font-family: "PingFangSC-Thin";
        font-size: 22px;
        font-weight: 100;
        color: #999999;
        margin: 0 0 16px 28px;
    }
    .orderDetail-box-orderInfo{
        width: 734px;
        height: 35px;
        font-family: "PingFangSC-Medium";
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        padding: 30px 0 16px 39px;
        border-bottom: 1px solid #d8d8d8;
        margin-left: 20px;
    }
    .orderDetail-box-infor{
        width: 780px;
        margin-bottom: 24px;
        height: 176px;
    }
    .orderDetail-box-infor>div{
        float: left;
    }
    .orderDetail-box-infor>div:nth-of-type(1){
        margin: 16px 70px  0 36px;
    }
    .orderDetail-box-infor>div:nth-of-type(2){
        margin: 16px 85px  0 0;
    }
    .orderDetail-box-infor>div:nth-of-type(3){
        margin-top: 16px;
    }
    .orderDetail-box-infor>div>div{
        margin-bottom: 24px;
    }
    .orderDetail-box-infor>div>div>span:nth-of-type(1){
        font-family: "PingFangSC-Medium";
        font-size: 14px;
        font-weight: 500;
        color: #333333;
    }
    .orderDetail-box-infor>div>div>span:nth-of-type(2){
        font-family: "PingFangSC-Regular";
        font-size: 14px;
        color: #999999;
    }
    .orderDetail-address{
        margin:0 0 24px 36px;
        font-size: 14px;
        height: 19px;
    }
    .orderDetail-address>div{
        float: left;
    }
    .orderDetail-address>div:nth-of-type(2){
        color: #999999;
        margin-right: 30px;
    }
    .orderDetail-address>div:nth-of-type(3){
        cursor: pointer;
        padding: 0 8px;
        border:1px solid #999999;
        border-radius: 5px;
    }
    .orderDetail-no{
        height: 40px;
        font-size: 14px;
    }
    .orderDetail-no>div{
        margin-left: 36px;
    }
    .orderDetail-no>div>span:nth-of-type(2){
       color: #999999;
    }
    .orderDetail-box-changeTitle{
        width: 780px;
        height: 44px;
        margin-bottom: 24px;
        border-bottom:1px solid #d8d8d8;
        margin-left: 20px;
    }
    .orderDetail-box-changeTitle>div>div{
        float: left;
        width: 100px;
        height: 44px;
        text-align: center;
        font-family: "PingFangSC-Regular";
        font-size: 20px;
        color: #999999;
        cursor: pointer;
    }
    .orderDetail-box-changeTitle>div>div:nth-of-type(1){
        margin: 0 30px 0 29px;
    }
    .infor .div1,.about .div2{
        border-bottom:1px solid #EB5E00;
        font-family: "PingFangSC-Medium";
        font-size: 20px;
        font-weight: 500;
        color: #000000;
    }
    .orderDetail-box-tradeMark{
        width: 780px;
        margin-bottom: 54px;
    }
    .orderDetail-box-image{
        height: 120px;
        margin-bottom: 24px;
    }
    .orderDetail-box-image>div{
        float: left;
    }
    .orderDetail-box-image>div:nth-of-type(1){
        font-family: "PingFangSC-Medium";
        font-size: 14px;
        font-weight: 500;
        margin-left: 35px;
        color: #333333;
    }
    .orderDetail-box-image>div:nth-of-type(2){
        width: 120px;
        height: 120px;
        border: solid 1px #d8d8d8;
        text-align: center;
        margin-right: 85px;
        margin-left: 10px;
    }
    .orderDetail-box-image>div:nth-of-type(2)>img{
        width: 120px;
        height: 120px;
    }
    .orderDetail-box-image>div:nth-of-type(3){
        width: 225px;
    }
    .orderDetail-box-image>div:nth-of-type(3)>span{
        font-family: "PingFangSC-Medium";
        font-size: 14px;
        font-weight: 500;
        color: #333333;
    }
    .orderDetail-box-image>div:nth-of-type(3)>span:nth-of-type(2){
        color: #EB5E00;
    }
    .orderDetail-box-image>div:nth-of-type(4)>span{
        font-family: "PingFangSC-Medium";
        font-size: 14px;
        font-weight: 500;
        color: #333333;
    }
    .orderDetail-box-image>div:nth-of-type(4)>span:nth-of-type(2){
        color:#999990;
    }
    .orderDetail-box-information{
        margin-bottom: 24px;
    }
    .orderDetail-box-information>span:nth-child(2n+1){
         font-family: "PingFangSC-Medium";
         font-size: 14px;
         font-weight: 500;
         color: #333333;
         vertical-align: middle;
    }
    .orderDetail-box-information>span:nth-child(2n){
         font-family: "PingFangSC-Regular";
         font-size: 14px;
         color: #999999;
         vertical-align: middle;
    }
    .span1{
        margin-left: 21px;
        margin-right: 6px;
    }
    .span2{
        display: inline-block;
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
    .span3{
        display: inline-block;
        width: 142px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
    .orderDeatil-box-choseTy{
        width: 780px;
        overflow: hidden;
    }
    .orderDetail-box-good-type{
        float: left;
    }
    .orderDetail-box-good-detail{
        float: left;
        margin-left: 25px;
    }
    .orderDetail-box-good-type{
        font-family: "PingFangSC-Medium";
        font-size: 14px;
        font-weight: 500;
        margin-left: 35px;
        color: #333333;
    }
    .orderDetail-box-good-detail-div1{
        margin-bottom: 12px;
        font-family: "PingFangSC-Regular";
        font-size: 14px;
        color: #999990;
    }
    .orderDetail-box-good-detail-div2{
        width: 640px;
        overflow: hidden;
    }
    .orderDetail-box-good-detail-div2>div{
        float: left;
        font-family: "PingFangSC-Thin";
        font-size: 14px;
        font-weight: 100;
        color: #999990;
        margin:0 24px 10px 0;
    }
    .orderDetail-box-risk{
        width: 780px;
        margin-bottom: 54px;
    }
    .orderDetail-box-risk-title{
        margin-bottom: 24px;
        font-family: "PingFangSC-Medium";
        font-size: 14px;
        font-weight: 500;
        color: #333333;
    }
    .orderDetail-box-risk-title>span:nth-of-type(1){
        margin:0 204px 0 82px;
    }
    .orderDetail-box-risk-title>span:nth-of-type(2){
        margin-right:200px;
    }
   .orderDetail-box-planDetail{
       margin-left:29px;
   }
   .orderDetail-box-rate,.orderDetail-box-refund>div:nth-of-type(1){
        width: 741px;
        height: 28px;
        font-family: "PingFangSC-Medium";
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        padding: 0 0 16px 39px;
        border-bottom: 1px solid #d8d8d8;
        margin-left: 20px;
   }
   .orderDetail-box-pragrom{
       margin-bottom: 60px;
   }
   .orderDetail-box-pragrom1{
       margin: 20px 0 40px 50px;
   }
   .orderDetail-box-pragrom1>span{
       font-size: 14px;
       vertical-align: middle;
       color: #333333;
   }
   .orderDetail-box-pragrom1>span:nth-of-type(2){
       color: #999999;
       margin-right: 40px;
   }
   .orderDetail-box-pragrom1>span:nth-of-type(3){
       color: #EB5E00;
       cursor: pointer;
   }
   .orderDetail-box-pragrom2{
       margin-left: 50px;
   }
   .p1{
       font-family: "PingFangSC-Thin";
       font-size: 10px;
       font-weight: 100;
       color: #EB5E00;
       margin: 8px 0;
   }
   .p2{
       font-family: "PingFangSC-Thin";
       font-size: 10px;
       font-weight: 100;
       color: gray;
       margin: 8px 0;
   }
   .orderDetail-box-pragrom-div3-list>div:nth-of-type(1){
       left: 60px;
   }
   .orderDetail-box-pragrom-div3-list>div:nth-of-type(2){
       left: 250px;
   }
   .orderDetail-box-pragrom-div3-list>div:nth-of-type(3){
       left: 460px;
   }
   .orderDetail-box-pragrom-div3-list>div:nth-of-type(4){
       left: 650px;
   }
   .orderDetail-box-file{
       margin-bottom: 54px;
   }
   .orderDetail-box-file-infor,.orderDetail-box-suggest-top{
        width: 761px;
        height: 28px;
        font-family: "PingFangSC-Medium";
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        padding: 0 0 16px 19px;
        border-bottom: 1px solid #d8d8d8;
        margin-left: 20px;
   }
   .orderDetail-box-file-wrap{
       width: 780px;

   }
   .orderDetail-box-file-top{
       float: left;
       width: 230px;
       height: 64px;
       margin: 32px 40px 0 0;
   }
   .orderDetail-box-file-top:nth-of-type(3n){
       margin-right: 0;
   }
   .orderDetail-box-file-top>div{
       float: left;
   }
   .orderDetail-box-file-top-div>div:nth-of-type(1){
        font-family: "PingFangSC-Regular";
        font-size: 14px;
        color: #333333;
        margin: 4px 0 12px;
        width: 115px;
        overflow: hidden;    
        text-overflow:ellipsis;    
         white-space: nowrap;  
   }
   .orderDetail-box-file-top-div>div:nth-of-type(2)>a{
       text-decoration: none;
        font-family: "PingFangSC-Regular";
        font-size: 14px;
        color: #EB5E00;
        cursor: pointer;
   }
   .orderDetail-box-file-top-div>div:nth-of-type(2)>a:nth-of-type(1){
       margin-right: 30px;
   }
   .orderDetail-box-file-top-img{
        width: 64px;
        height: 64px;
        background: url("../../../../static/images/datawrite.png") no-repeat;
        background-size: cover;
        margin-right: 10px;
   }
   .orderDetail-box-suggest{
       margin-bottom: 54px;
   }
   .orderDetail-box-suggest-detail{
       margin-top: 30px;
       height: 135px;
   }
   .orderDetail-box-suggest-detail>div{
       width: 260px;
       float: left;
       text-align: center;
       cursor: pointer;
   }
   .orderDetail-box-suggest-detail>div>img{
       width: 64px;
       height: 64px;
   }
   .orderDetail-box-suggest-detail>div>p{
        font-family: "PingFangSC-Medium";
        font-size: 16px;
        font-weight: 500;
        color: #333333;
   }
   .orderDetail-box-suggest-detail>div>p:nth-of-type(1){
       margin: 15px 0 8px;
   }
   .orderDetail-box-refund{
       margin-bottom: 160px;
   }
   .orderDetail-box-refund>div:nth-of-type(2){
        font-family: "PingFangSC-Regular";
        font-size: 20px;
        color: #EB5E00;
        margin: 16px 0 0 39px;
        cursor: pointer;
    }
    
    .drawback{
        background: rgba(0,0,0, 0.8);
        position: fixed;
        z-index: 20;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .drawback-div{
        width: 600px;
        height: 437px;
        background-color: #ffffff;
        position: absolute;
        top:50%;
        left: 50%;
        z-index: 30;
        transform: translate(-50%,-50%);
    }
    .drawback-div>img{
        position: absolute;
        right: 17px;
        top: 17px;
        width: 17px;
        height: 17px;
        cursor: pointer;
    }
    .drawback-div>div{
        position: absolute;
        top:30px;
        left: 112px;
        width: 410px;
    }
    .back-div1{
        font-size: 16px;
        color: #333333;
        width: 64px;
        margin: 0 auto 32px;
    }

    .back-div2{
        height: 20px;
        margin-bottom: 30px;
    }
    .back-div2>div{
        float: left;
         font-size: 14px;
        color: #333333;
        margin-right: 18px;
    }
    .back-div-chose{
        width: 90px;
        height: 20px;
        border-radius: 4px;
        text-align: center;
        line-height: 20px;
        border: solid 1px #d6d6d6;
        cursor: pointer;
    }
    .refundStyle{
        border-color: #EB5E00;
        color: #EB5E00!important;
    }
    .back-div3>span{
        font-size: 14px;
        color: #333333;
        margin-right: 15px;
    }
    .back-div3{
        margin-bottom: 30px;
    }
    .back-div3>input{
        width: 298px;
        height: 32px;
        border-radius: 4px;
        border: solid 1px #d6d6d6;
        padding-left: 10px;
        outline: none;
    }
    .back-div4{
        height: 96px;
        margin-bottom: 33px;
    }
    .back-div4>div{
        float: left;
    }
    .back-div4>div:nth-of-type(1){
        font-size: 14px;
        color: #333333;
        margin-right: 18px;
    }
    .write{
        width: 300px;
        height: 96px;
        border-radius: 4px;
        border: solid 1px #d6d6d6;
        outline: none;
    }
    .back-div5{
        width: 108px;
        height: 36px;
        border-radius: 4px;
        background: #EB5E00;
        color: #ffffff;
        text-align: center;
        line-height: 36px;
        margin: 0 auto;
        font-size: 14px;
        cursor: pointer;
    }
    .star-div{
        float:right;
        margin-right:250px;
        width:200px;
    }
    .star-div>span{
        float:left;
        margin-right:8px;
    }
    .ml20 {
      margin-left: 20px;
    }
    .address-pick{
        background: rgba(0,0,0, 0.5);
        position: fixed;
        z-index: 20;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .pick-form{
        width: 582px;
        height: 434px;
        background: #ffffff;
        position: absolute;
        top:50%;
        left: 50%;
        z-index: 30;
        transform: translate(-50%,-50%);
    }
    .pick-form>img{
        width: 24px;
        height: 24px;
        position: absolute;
        right:20px;
        top:20px;
        cursor: pointer;
    }
    .form-pick{
        position: absolute;
        width: 400px;
        height: 240px;
        top:81px;
        left: 96px;
        overflow-y: auto;
        /* border:1px solid red; */
    }
    .form-pick>div{
        margin-bottom: 15px;
        font-size: 16px;
        color: #999990;
        cursor: pointer;
    }
    .form-pick>div>span{
        margin-right: 10px;
    }
    .colorChange{
        border:1px solid red!important;
    }
    .address-edit{
        width: 80px;
        height: 36px;
        display: inline-block;
        border-radius: 4px;
        background-color:#EB5E00;
        position: absolute;
        bottom:60px;
        left:245px;
        text-align: center;
        line-height: 36px;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
    }
</style>
<style>
.el-timeline-item__node{
    background: #f97527;
}
.el-timeline-item__tail{
    border-left: 1px solid #f97527;
}
.el-timeline-item__content{
    width: 200px;
    font-family: "PingFangSC-Thin";
    font-size: 14px;
    font-weight: 100;
    color: #333333;
}
.orderDetail-box-pragrom2 .el-timeline-item__timestamp{
    position: absolute;
    left: 225px;
    top:-5px;
}
.el-timeline-item__timestamp{
    position: absolute;
    left: 280px;
    top:-8px;
}
.el-timeline-item__content>span{
    position: absolute;
    left: 540px;
    top:-5px;
    font-family: "PingFangSC-Regular";
    font-size: 14px;
    color: #999990;
}

</style>
